/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpopnsorGroups } from "../../redux/slice/globalSlice";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import background from "./../../assets/foro-go/backgrond/bg_inicio.webp";
import MailchimpForm from "../../components/MailchimpForm";

export default function Sponsors() {
  const { sposorGroups } = useSelector((state) => state.global);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let auxSponsors = [...sposorGroups];
  auxSponsors?.sort((a, b) => a.priority - b.priority);

  const settingsToSlider = (name) => {
    let settings = [];
    if (name === "PREMIER") {
      console.log("object");
      settings = {
        className: "center",
        centerMode: true,
        lazyLoad: true,
        dots: false,
        speed: 5000,
        arrows: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 1000, // Tiempo en milisegundos entre transiciones
      };
    } else {
      settings = {
        className: "center",
        centerMode: true,
        lazyLoad: true,
        dots: false,
        speed: 5000,
        arrows: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        cssEase: "linear",
        pauseOnHover: false,
        autoplaySpeed: 1000, // Tiempo en milisegundos entre transiciones
      };
    }

    return settings;
  };

  const style = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  const onRedirect = (e) => {
    if (e.webSite !== "") {
      window.location.href = e.webSite;
    }
  };

  useEffect(() => {
    if (sposorGroups.length === 0) {
      dispatch(getSpopnsorGroups());
    }
  }, []);

  if (sposorGroups.length > 0 && auxSponsors.length > 0) {
    return (
      <section
        id="aliados-estrategicos"
        style={{ ...style }}
        className="partner-area bg-image ptb-120"
      >
        <div className="container">
          <div className="section-title">
            <img
              alt=""
              src={require("./../../assets/foro-go/titulos/t_aliados.webp")}
            />
          </div>
          {auxSponsors?.map((groups) => (
            <div key={groups.id}>
              <div className="partner-title platinum-sponsor">
                <img alt="" width={700} src={groups.image} />
              </div>
              <div className="row">
                <Slider {...settingsToSlider(groups.name)}>
                  {groups.sponsors?.map((sponsor, i) => {
                    return (
                      <div key={i} className="col-lg-12 col-md-12">
                        <Link
                          onClick={() => {
                            onRedirect(sponsor);
                          }}
                          to=""
                        >
                          <img
                            src={
                              sponsor.logoImage
                                ? sponsor.logoImage
                                : require("../../assets/foro-go/icono.png")
                            }
                            width="60%"
                            alt="Partner Logo"
                          />
                        </Link>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          ))}
        </div>
        {!isActive ? (
          <div className="d-flex justify-content-center mt-3">
            <div className="d-flex justify-content-center flex-column w-25 text-center">
              <h3 style={{ color: "#ffff" }}>
                ¿Quieres ser Aliado Estratégico?
              </h3>
              <button
                onClick={() => {
                  navigate("/mailchimpForm");
                }}
                className="btn btn-primary"
              >
                <strong>Contáctanos</strong>
              </button>
            </div>
          </div>
        ) : (
          <>
            <MailchimpForm />
          </>
        )}
      </section>
    );
  } else {
    return null;
  }
}
