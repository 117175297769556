import React, { useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import "./styles.css";

export default function Notice() {
  useEffect(() => {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Opcional: agrega un desplazamiento suave
      });
    }
    scrollToTop();
  }, []);

  return (
    <>
      <Header />
      <div style={{ background: "#181f2a" }} className="page-title-area">
        <h1>AVISO DE PRIVACIDAD INTEGRAL</h1>
      </div>
      <section className="schedule-area-tc">
        <h5>AVISO DE PRIVACIDAD INTEGRAL</h5>

        <p>
          Conforme a lo previsto en la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares (en adelante LA LEY), el
          Reglamento a la Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares (en adelante EL REGLAMENTO), en comunión
          con Los Lineamientos del Aviso de Privacidad (LINEAMIENTOS),
          publicados en el Diario Oficial de la Federación, en fecha 17 de Enero
          del año 2013, al que refiere el artículo 43 de La Ley; hacemos de su
          conocimiento que, INNOVACIÓN Y DESARROLLOS SMARTDEV S.A. DE C.V. (en
          adelante smartDEV), con domicilio en María Dolores #203 Col. El
          Mirador Oriental, León Guanajuato C.P. 37500, pone a su disposición el
          presente Aviso de Privacidad Integral, con la finalidad de proteger
          los datos personales que ha de proporcionar como consecuencia de su
          participación como asistente en el marco del Magno Evento denominado
          FORO GO 2024 posibilidades infinitas, en los términos siguientes:
        </p>
        <h5>GLOSARIO</h5>

        <dl>
          <dt>I.1. AGENCIA:</dt>
          <dd>
            Proveedor contratado por COFOCE para realizar la estrategia de
            promoción en medios digitales del EVENTO.
          </dd>

          <dt>I.2. APP:</dt>
          <dd>
            Aplicación móvil a utilizar el día del evento por los asistentes y
            aliados estratégicos.
          </dd>

          <dt>I.3. COFOCE:</dt>
          <dd>
            Coordinadora de Fomento al Comercio Exterior del Estado de
            Guanajuato. Organizador del EVENTO.
          </dd>

          <dt>I.4. CONSENTIMIENTO:</dt>
          <dd>
            Manifestación de la voluntad del titular de los datos mediante la
            cual se efectúa el tratamiento de los mismos.
          </dd>

          <dt>I.5. COOKIES:</dt>
          <dd>
            Archivo de datos que se almacena en el disco duro del equipo de
            cómputo o del dispositivo de comunicaciones electrónicas de un
            usuario al navegar en un sitio de internet específico.
          </dd>

          <dt>I.6. DATOS PERSONALES:</dt>
          <dd>
            Cualquier información concerniente a una persona física identificada
            o identificable.
          </dd>

          <dt>I.7. DATOS PERSONALES SENSIBLES:</dt>
          <dd>
            Aquellos datos personales íntimos del Titular de los Datos, que por
            ejemplo, puedan revelar aspectos raciales, étnicos, estado de salud
            presente o futuro, información genética, creencias religiosas,
            filosóficas y morales, afiliación sindical, opiniones políticas,
            preferencia sexual, entre otros.
          </dd>

          <dt>I.8. DERECHOS ARCO:</dt>
          <dd>
            Son los derechos de acceso, rectificación, cancelación y oposición
            de datos personales.
          </dd>

          <dt>I.9. ENTORNO DIGITAL:</dt>
          <dd>
            Es el ámbito conformado por la conjunción de hardware, software,
            redes, aplicaciones, servicios o cualquier otra tecnología de la
            información que permite el intercambio o procesamiento informatizado
            o digitalizado de datos.
          </dd>

          <dt>I.10. EVENTO:</dt>
          <dd>FORO GO 2024 posibilidades infinitas.</dd>

          <dt>I.11. PORTAL:</dt>
          <dd>
            Medio único autorizado por COFOCE a través del cual se pone a
            disposición la liga de acceso para compra de boletos de asistencia
            al EVENTO y que será el siguiente:{" "}
            <a href="https://foro-go.com">https://foro-go.com</a>
          </dd>

          <dt>I.12. RESPONSABLE:</dt>
          <dd>
            Persona física o moral de carácter privado que decide sobre el
            tratamiento de datos personales.
          </dd>

          <dt>I.13. SEDE:</dt>
          <dd>
            Lugar físico donde se realizará el EVENTO de manera presencial, los
            días 18 y 19 de Octubre 2023 en las instalaciones de POLIFORUM León,
            ubicado en Blvd. Adolfo López Mateos esquina Blvd. Francisco Villa
            s/n Col. Oriental CP. 37510, en la ciudad de León Guanajuato.
          </dd>

          <dt>I.14. TERCERO:</dt>
          <dd>
            La persona física o moral, nacional o extranjera, distinta del
            titular o del responsable de los datos.
          </dd>

          <dt>I.15. TITULAR:</dt>
          <dd>La persona física a quien corresponden los datos personales.</dd>

          <dt>I.16. TRANSFERENCIA:</dt>
          <dd>
            Toda comunicación de datos realizada a persona distinta del
            responsable o encargado del tratamiento.
          </dd>

          <dt>I.17. TRATAMIENTO:</dt>
          <dd>
            La obtención, uso, divulgación o almacenamiento de datos personales,
            por cualquier medio.
          </dd>
        </dl>
        <h5>OBJETO DEL PRESENTE AVISO DE PRIVACIDAD</h5>

        <p>
          En armonía con las disposiciones legales aplicables, se hace de su
          conocimiento el objeto del presente Aviso de Privacidad:
        </p>

        <ol type="a">
          <li>
            <p>
              a) Que el TITULAR de los DATOS PERSONALES, esté enterado de la
              Información que se recaba, cómo se almacena, quién es el
              responsable, así como el domicilio del responsable y cuál es la
              finalidad de recabar los DATOS PERSONALES con motivo de su
              participación en el EVENTO.
            </p>
          </li>
          <li>
            <p>
              b) Hacer de su conocimiento los mecanismos para que el Titular
              pueda manifestar su negativa para el tratamiento de sus datos
              personales.
            </p>
          </li>
          <li>
            <p>
              c) Dar a conocer los medios para que el Titular ejerza sus
              derechos ARCO.
            </p>
          </li>
          <li>
            <p>
              d) Establecer el mecanismo para que el Titular pueda revocar su
              consentimiento para el tratamiento de sus datos personales.
            </p>
          </li>
          <li>
            <p>
              e) Las opciones que ofrece el Titular para limitar el uso o
              divulgación de sus datos personales.
            </p>
          </li>
          <li>
            <p>f) Cláusula de transferencia.</p>
          </li>
          <li>
            <p>
              g) Medio por el cual el Responsable comunicará al Titular los
              cambios en el Aviso de Privacidad.
            </p>
          </li>
        </ol>
        <h2>DATOS PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO</h2>

        <p>
          Los DATOS PERSONALES que son recabados por el RESPONSABLE de manera
          enunciativa, mas no limitativa, corresponden a información de personas
          físicas con actividad empresarial y datos de representación y
          contacto, consistentes en nombre y apellidos, funciones o puestos
          desempeñados, así como algunos de los siguientes datos laborales:
          domicilio físico, correo electrónico, teléfono y datos similares que
          le sean atribuibles al TITULAR. Así como aquellos relacionados para la
          generación de facturas, por lo que será requerido, adicionalmente del
          TITULAR, su Registro Federal de Contribuyentes a través de su
          Constancia de Situación Fiscal.
        </p>

        <p>
          Los DATOS PERSONALES que se proporcionen por el TITULAR en los
          hipervínculos o enlaces (links) que permitan acceder a páginas web
          distintas al PORTAL y/o la APP serán bajo los términos que en su caso
          se estipulen en aquellos.
        </p>

        <p>
          <strong>DATOS PERSONALES SENSIBLES QUE SE TRATARÁN.</strong> No serán
          recabados ni tratados DATOS PERSONALES SENSIBLES.
        </p>
        <h3>FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h3>

        <ol>
          <li>
            <strong>FINALIDAD PRIMARIA</strong>.- Corresponde a aquellas
            finalidades que dieron origen y son necesarias para la existencia,
            mantenimiento y cumplimiento de la relación jurídica entre smartDEV
            y el Titular, siendo las siguientes:
            <ul>
              <li>
                Confirmar su identidad como participante del Evento y realizar
                gestiones relacionadas con la participación del Titular en el
                Evento, así como proveer los servicios necesarios para la
                organización del mismo.
              </li>
              <li>
                Verificar la información proporcionada y corroborar que sea
                veraz, actual y completa.
              </li>
              <li>
                Contactar al Titular para cualquier cuestión relativa al Evento,
                incluyendo cambios, modificaciones o cancelaciones.
              </li>
              <li>
                Generar estadísticas, informes y análisis para la mejora
                continua de los servicios proporcionados.
              </li>
              <li>
                Dar cumplimiento a las obligaciones legales y contractuales
                derivadas de la participación del Titular en el Evento.
              </li>
              <li>
                Gestionar la facturación y cobro de los servicios
                proporcionados.
              </li>
            </ul>
          </li>

          <li>
            <strong>FINALIDAD SECUNDARIA</strong>.- Corresponde a aquellas
            finalidades que no son necesarias para la existencia, mantenimiento
            y cumplimiento de la relación jurídica entre smartDEV y el Titular,
            pero que son importantes para mejorar la experiencia del Titular,
            siendo las siguientes:
            <ul>
              <li>
                Enviar información sobre futuros eventos organizados por COFOCE,
                smartDEV o aliados estratégicos.
              </li>
              <li>
                Realizar encuestas de satisfacción y análisis de la calidad de
                los servicios proporcionados.
              </li>
              <li>
                Enviar comunicaciones promocionales y publicitarias relacionadas
                con los servicios proporcionados.
              </li>
            </ul>
          </li>
        </ol>
      </section>

      <Footer />
    </>
  );
}
