import React, { useEffect } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Pricing from "../components/Pricing";
import Program from "../components/Program";
import Sponsors from "../components/Sponsors";
import Speakers from "../components/Speakers";
import HeroSection from "../components/HeroSection";
import { useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { GoTop } from "../components/buttons/GoTop";
import FloatingWhatsAppButton from "../components/buttons/WhatsAppBtn";
export default function Root() {
  const location = useLocation();

  useEffect(() => {
    const section = location.pathname.substring(1);
    if (section) {
      scroller.scrollTo(section, {
        duration: 100,
        smooth: "easeOutQuad",
      });
    }
  }, [location]);

  return (
    <div>
      <Header />
      <Element name="home">
        <HeroSection />
      </Element>
      <Element name="banner">
        <Banner />
      </Element>
      <Element name="speakers">
        <Speakers />
      </Element>
      <Element name="programa">
        <Program />
      </Element>
      <Element name="pricing">
        <Pricing />
      </Element>
      <Element name="aliados-estrategicos">
        <Sponsors />
      </Element>
      <Element name="contact">
        <Footer />
      </Element>
      <GoTop />
      <FloatingWhatsAppButton />
    </div>
  );
}
