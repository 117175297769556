import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Pricing = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const pricingRef = useRef(null);
  const url = "https://boletos.foro-go.com";

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    },
  }));

  /* eslint-disable */
  const onClickGrl = () => {
    window.open(`${url}/?ticketId=86`, "_blank");
  };

  const onClickVip = () => {
    window.open(`${url}/?ticketId=87`, "_blank");
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];

      setIsVisible(isIntersecting);
    });

    if (pricingRef.current) {
      observer.observe(pricingRef.current);
    }

    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef?.current);
      }
    };
  }, []);

  if (isVisible && viewPricing === 0) {
    setTimeout(() => {
      incrementPricing();
    }, 5000);
  }

  const PromoText = () => {
    return (
      <div className="promo-text">
        <h2>¡Hasta 3 meses sin intereses!</h2>
      </div>
    );
  };

  return (
    <section id="pricing" className="pricing-area bg-image ptb-120">
      <div className="container">
        <div className="section-title">
          <img
            alt=""
            src={require("./../../assets/foro-go/titulos/t_boletos.webp")}
          />
        </div>

        <div className="row flex-row-reverse justify-content-evenly">
          <div className="col-lg-4 col-md-6">
            <div className="pricing-table-box">
              <div className="pricingTable-header">
                <div className="ticket-type">GENERAL</div>
                <div className="price-value p-v"></div>
              </div>
              <br />
              <h3 style={{ textDecoration: "underline", color: "#77ff00" }}>
                CUPO LIMITADO
              </h3>
              <ul className="pricing-content">
                <li>Acceso a ponencias 16 y 17 Octubre</li>
                <li>Kit de bienvenida general</li>
                <li>
                  Encuentro de negocios 16 y 17 Octubre (previo agendamiento)
                </li>
                <li>Acceso a piso de exhibición / Área de networking</li>
                <li>Coffee Break</li>
                <li>Cocktail de cierre 17 Octubre</li>
                <li>Constancia de participación</li>
                <li>Traducción simultánea Inglés- Español</li>
              </ul>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {/* <Link
                onClick={() => {
                  onClickGrl();
                }}
                to="#"
                className="btn btn-primary"
              >
                <strong>Comprar Boleto</strong>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Pricing;
