import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import ErrorPage from "../views/erro-page";
import MailchimpForm from "../components/MailchimpForm";
import Hotels from "../components/hotels";
import Notice from "../components/Notice";
import Terms from "../components/Terms";
import Program from "../components/Program";

export const RoutesObj = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/home", element: <Root /> },
      { path: "/banner", element: <Root /> },
      { path: "/speakers", element: <Root /> },
      { path: "/programa", element: <Root /> },
      { path: "/pricing", element: <Root /> },
      {
        path: "/aliados-estrategicos",
        element: <Root />,
      },
      { path: "/contact", element: <Root /> },
    ],
  },
  {
    path: "/mailchimpForm",
    element: <MailchimpForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/hoteles",
    element: <Hotels />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/aviso",
    element: <Notice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terminos",
    element: <Terms />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/programa/dia-1",
    element: <Program />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/programa/dia-2",
    element: <Program />,
    errorElement: <ErrorPage />,
  },
]);
