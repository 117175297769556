import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const eventId = process.env.REACT_APP_EVENTID;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URI,
});

export const getSpeakers = createAsyncThunk(
  "tickets/getSpeakers",
  async (args, thunkAPI) => {
    try {
      const response = await axiosInstance.request({
        url: `/events/${eventId}/speakers`,
        method: "get",
      });

      return { data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Error al consultar los tickets. Error: ",
        error
      );
    }
  }
);

export const getConferences = createAsyncThunk(
  "tickets/getConferences",
  async (args, thunkAPI) => {
    try {
      const response = await axiosInstance.request({
        url: `/events/${eventId}/conferences`,
        method: "get",
        params: {pageSize: 30}
      });

      return { data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Error al consultar los tickets. Error: ",
        error
      );
    }
  }
);

export const getSpopnsorGroups = createAsyncThunk(
  "tickets/getSpopnsorGroups",
  async (args, thunkAPI) => {
    try {
      const response = await axiosInstance.request({
        url: `/events/${eventId}/sponsorgroups`,
        method: "get",
      });

      return { data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Error al consultar los tickets. Error: ",
        error
      );
    }
  }
);

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    value: 0,
    speakers: [],
    conferences: [],
    sposorGroups: [],
    daysToRender: [],
    pendingSpeakers: false,
    pendingConferences: false,
    pendingSponsorGroups: false,
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpeakers.fulfilled, (state, action) => {
        state.pendingSpeakers = false;
        state.speakers = action.payload?.data;
      })
      .addCase(getSpeakers.pending, (state, action) => {
        state.pendingSpeakers = true;
      })
      .addCase(getSpeakers.rejected, (state, action) => {
        state.pendingSpeakers = false;
      })
      .addCase(getConferences.fulfilled, (state, action) => {
        state.pendingConferences = false;
      })
      .addCase(getConferences.pending, (state, action) => {
        state.pendingConferences = true;
      })
      .addCase(getConferences.rejected, (state, action) => {
        state.pendingConferences = false;
      })
      .addCase(getSpopnsorGroups.fulfilled, (state, action) => {
        state.sposorGroups = action.payload?.data;
        state.pendingSponsorGroups = false;
      })
      .addCase(getSpopnsorGroups.pending, (state, action) => {
        state.pendingSponsorGroups = true;
      })
      .addCase(getSpopnsorGroups.rejected, (state, action) => {
        state.pendingSponsorGroups = false;
      });
  },
});

export const { increment, decrement, incrementByAmount } = globalSlice.actions;

export default globalSlice.reducer;
