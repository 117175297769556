import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getConferences } from "../../redux/slice/globalSlice";
import Header from "../Header";
import Footer from "../Footer";
import ModalDetail from "../ModalDetail";
/* eslint-disable */
const EventSchedules = forwardRef((props, ref) => {
  const [speakerSelected, setSpeakerSelected] = useState("");
  const [daysToRender, setDaysToRender] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const scheduleRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;

    tabcontent = document.getElementsByClassName("tabs_item");

    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    if (evt) {
      tablinks = document.getElementsByTagName("li");

      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace("current", "");
      }
    }

    const tabElement = document.getElementById(tabName);

    if (tabElement) {
      tabElement.style.display = "block";
    } else {
      console.error(`Element with id ${tabName} not found.`);
    }

    if (evt) {
      evt.currentTarget.className += " current";
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e) => {
    setIsOpen(true);
    setSpeakerSelected(e);
  };

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      scheduleRef.current.scrollIntoView({ behavior: "smooth" });
    },
  }));

  const getConferenceTime = (startTime, endTime) => {
    const timestamp = startTime;

    const date = new Date(timestamp * 1000);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedTime = date.toLocaleTimeString("es-MX", options);

    const timestampEnd = endTime;

    const dateEnd = new Date(timestampEnd * 1000);

    const optionsEnd = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedTimeEnd = dateEnd.toLocaleTimeString("es-MX", optionsEnd);
    return `${formattedTime} - ${formattedTimeEnd}`;
  };

  useEffect(() => {
    const handleTabActivation = () => {
      if (location.pathname === "/programa/dia-1") {
        openTabSection(null, "tab1");
      } else if (location.pathname === "/programa/dia-2") {
        openTabSection(null, "tab2");
      }
    };

    // Delay execution to ensure DOM elements are present
    const timeoutId = setTimeout(handleTabActivation, 1000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  const monthsName = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const getConferences_ = () => {
    dispatch(getConferences())
      .unwrap()
      .then((e) => {
        const days = [];
        e?.data.forEach((thisConference) => {
          var d = new Date(0);
          d.setUTCSeconds(thisConference.startDate);

          const findDay = days.find(
            (currentDay) => currentDay.getDate() === d.getDate()
          );

          if (!findDay) {
            days.push(d);
          }
        });
        setDaysToRender([...days]);
        setConferences(e.data);
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  useEffect(() => {
    getConferences_();
  }, []);

  return (
    <>
      {(location.pathname === "/programa/dia-1" ||
        location.pathname === "/programa/dia-2") && <Header />}
      <section id="programa" className="schedule-area bg-image ptb-120">
        <div className="container">
          <img
            alt="img-program"
            src={require("./../../assets/foro-go/titulos/t_programa.webp")}
          />
          <br />
          <br />
          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  {daysToRender
                    .sort((a, b) => a - b)
                    .map((item, index) => (
                      <li
                        key={`day-render-${item.getDate()}`}
                        onClick={(e) => openTabSection(e, `tab${index + 1}`)}
                        className={
                          location.pathname === "/programa/dia-1" && index === 0
                            ? "current"
                            : location.pathname === "/programa/dia-2" &&
                              index === 1
                            ? "current"
                            : index === 0 &&
                              (location.pathname !== "/programa/dia-1" ||
                                location.pathname !== "/programa/dia-2")
                            ? "current"
                            : ""
                        }
                      >
                        <Link style={{ color: "black", height: "" }}>
                          <strong>
                            {index + 1 === 1 ? "primer día" : "segundo día"}
                          </strong>
                          <span>
                            {item.getDate()}{" "}
                            {monthsName[[Number(item.getMonth())]]}{" "}
                            {item.getFullYear()}
                          </span>
                        </Link>
                      </li>
                    ))}
                </ul>
                <div className="tab_content">
                  {daysToRender
                    ?.sort((a, b) => a - b)
                    .map((item, index) => (
                      <div
                        key={`tab${index + 1}`}
                        id={`tab${index + 1}`}
                        className="tabs_item"
                      >
                        <ul className="accordion">
                          {conferences
                            ?.sort((a, b) => a.startDate - b.startDate)
                            .map((conference) => {
                              let d = new Date(0);
                              d.setUTCSeconds(conference.startDate);
                              if (item.getDate() === d.getDate()) {
                                return (
                                  <li
                                    key={conference?.id}
                                    className="accordion-item"
                                  >
                                    <Link className="accordion-title" to="#">
                                      {conference?.image && (
                                        <div
                                          className="author"
                                          onClick={() => {
                                            openModal(speakersInfo[1]);
                                          }}
                                        >
                                          <img
                                            src={conference.image}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            alt="Author"
                                          />
                                        </div>
                                      )}

                                      <div className="schedule-info">
                                        <h3 style={{ color: "#ffff" }}>
                                          {conference.name}
                                        </h3>
                                        <ul>
                                          <li>
                                            <i className="icofont-wall-clock"></i>{" "}
                                            {getConferenceTime(
                                              conference.startDate,
                                              conference.endDate
                                            )}
                                          </li>
                                          {conference?.speakers &&
                                            conference?.speakers.map(
                                              (speaker) => (
                                                <li key={speaker.id}>
                                                  <i className="icofont-user-suited"></i>{" "}
                                                  Por{" "}
                                                  <span>{speaker.name}</span>{" "}
                                                  {speaker.position}
                                                </li>
                                              )
                                            )}
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDetail
          isOpen={isOpen}
          data={speakerSelected}
          onRequestClose={closeModal}
        />
      </section>
      {(location.pathname === "/programa/dia-1" ||
        location.pathname === "/programa/dia-2") && <Footer />}
    </>
  );
});

export default EventSchedules;
