import React from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import X from "./../../assets/icons/x_icono.png";
import "./styles.css";
ReactModal.setAppElement("#root");

function ModalDetail({ isOpen, onRequestClose, data }) {
  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "90%",
      height: "90%",
      padding: "20px",
      maxWidth: "1000px",
      maxHeight: "600px",
      position: "absolute",
      background: "#000",
      border: "1px solid #ffbb91",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      zIndex: 9999,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={onRequestClose}
    >
      <div className="modal-container">
        <div className="texts-container">
          <div className="img-content">
            <img
              alt=""
              className="modal-img"
              src={
                data.profileImage
                  ? data.profileImage
                  : require("../../assets/foro-go/icono.png")
              }
            />
          </div>
          <div className="texts-modal">
            <h1 className="text-name text-primary">{data.name}</h1>
            <div className="overflow-auto text-description p-4">
              {/* <p>{data.review}</p> */}
              <div dangerouslySetInnerHTML={{ __html: data?.review }} />
            </div>
          </div>
        </div>
        <div className="social-container">
          <hr style={{ borderTop: "3px solid #ffbb91" }} />
          <div className="single-footer-widget social-content">
            <ul className="social-links">
              {data?.facebook && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.facebook, "_blank");
                    }}
                    to="#"
                  >
                    <i className="icofont-facebook"></i>
                  </Link>
                </li>
              )}
              {data?.twitter && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.twitter, "_blank");
                    }}
                    to="#"
                  >
                    <img
                      src={X}
                      alt="Mi icono"
                      width={20}
                      height={20}
                      style={{ borderRadius: "50%" }}
                    />
                  </Link>
                </li>
              )}
              {data?.instagram && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.instagram, "_blank");
                    }}
                    to="#"
                  >
                    <i className="icofont-instagram"></i>
                  </Link>
                </li>
              )}
              {data?.linkedin && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.linkedin, "_blank");
                    }}
                    to="#"
                  >
                    <i className="icofont-linkedin"></i>
                  </Link>
                </li>
              )}
              {data?.youTube && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.youTube, "_blank");
                    }}
                    to="#"
                  >
                    <i className="icofont-youtube-play"></i>
                  </Link>
                </li>
              )}
              {data?.webSite && (
                <li>
                  <Link
                    onClick={() => {
                      window.open(data.webSite, "_blank");
                    }}
                    to="#"
                  >
                    <i className="icofont-web"></i>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default ModalDetail;
