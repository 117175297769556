import { useDispatch, useSelector } from "react-redux";
import { getSpeakers } from "../../redux/slice/globalSlice";
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Link } from "react-router-dom";
import ModalDetail from "../ModalDetail";
import CustomButtom from "./../buttons/OutLineCtmBtn";
import SpeakersImg from "./../../assets/foro-go/titulos/t_speakers.webp";
import background from "./../../assets/foro-go/backgrond/bg_speakers.webp";
/* eslint-disable */

const Speakers = forwardRef((props, ref) => {
  const { speakers } = useSelector((state) => state.global);
  const [speakerSelected, setSpeakerSelected] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const speakerRef = useRef(null);
  const dispatch = useDispatch();

  const style = {
    backgroundImage: `url(${background})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  const styleDes = {
    height: "4rem",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "normal",
    display: "inline-block",
    textOverflow: "ellipsis",
    padding: "1rem",
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e) => {
    setIsOpen(true);
    setSpeakerSelected(e);
  };

  useEffect(() => {
    dispatch(getSpeakers());

    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];

      setIsVisible(isIntersecting);
    });

    if (speakerRef.current) {
      observer.observe(speakerRef.current);
    }

    return () => {
      if (speakerRef.current) {
        observer.unobserve(speakerRef?.current);
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    scrollIntoView: () => {
      speakerRef.current.scrollIntoView({ behavior: "smooth" });
    },
  }));

  return (
    <section
      id="speakers"
      style={{ ...style }}
      className="speakers-area-two bg-image ptb-120"
    >
      <div className="container">
        <div className="section-title">
          <img alt="" src={SpeakersImg} />
        </div>
        <div className="row">
          {speakers.map((e) => {
            return (
              <div
                key={e.id}
                className="col-lg-4 col-sm-6"
                onClick={() => {
                  openModal(e);
                }}
              >
                <div className="single-speakers-box">
                  <div className="speakers-image">
                    <img
                      style={{
                        width: "400px",
                        height: "281px",
                        objectFit: "cover",
                      }}
                      src={
                        e.profileImage
                          ? e.profileImage
                          : require("../../assets/foro-go/icono.png")
                      }
                      alt="speaker"
                    />
                  </div>
                  <div className="speakers-content">
                    <span className="h3">{e.name}</span>
                    <span style={styleDes}>{e.position}</span>
                    <ul className="social">
                      {e.facebook && (
                        <li>
                          <Link
                            onClick={() => window.open(e.facebook, "_blank")}
                            to="#"
                          >
                            <i className="icofont-facebook"></i>
                          </Link>
                        </li>
                      )}
                      {e.twitter && (
                        <li>
                          <Link
                            onClick={() => {
                              window.open(e.twitter, "_blank");
                            }}
                            to="#"
                          >
                            <i className="icofont-twitter"></i>
                            {/* <img src={Xtwitter} alt="Teléfono" title='hi' /> */}
                          </Link>
                        </li>
                      )}
                      {e.instagram && (
                        <li>
                          <Link
                            onClick={() => {
                              window.open(e.instagram, "_blank");
                            }}
                            to="#"
                          >
                            <i className="icofont-instagram"></i>
                          </Link>
                        </li>
                      )}
                      {e.linkedin && (
                        <li>
                          <Link
                            onClick={() => {
                              window.open(e.linkedin, "_blank");
                            }}
                            to="#"
                          >
                            <i className="icofont-linkedin"></i>
                          </Link>
                        </li>
                      )}
                      {e.youTube && (
                        <li>
                          <Link
                            onClick={() => {
                              window.open(e.youTube, "_blank");
                            }}
                            to="#"
                          >
                            <i className="icofont-youtube-play"></i>
                          </Link>
                        </li>
                      )}
                      {e.webSite && (
                        <li>
                          <Link
                            onClick={() => {
                              window.open(e.webSite, "_blank");
                            }}
                            to="#"
                          >
                            <i className="icofont-web"></i>
                          </Link>
                        </li>
                      )}
                    </ul>
                    <br />
                    <CustomButtom child={"Ver más"} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ModalDetail
        isOpen={isOpen}
        data={speakerSelected}
        onRequestClose={closeModal}
      />
    </section>
  );
});

export default Speakers;
