import React, { useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import "./styles.css";

export default function Hotels() {
  useEffect(() => {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    scrollToTop();
  }, []);

  return (
    <>
      <Header />
      <section className="schedule-area bg-image">
        <div className="d-flex justify-content-center pt-5 ">
          <img
            width={650}
            alt=""
            src={require("./../../assets/foro-go/titulos/web/hoteles_sm.webp")}
          />
        </div>
        <div className="div-card-container">
          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                width={200}
                alt="hotels img"
                src={require("./../../assets/foro-go/hoteles/hotel-Metria.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Metria Hotel
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>Habitación Sencilla ó doble sin desayuno: $1,560 MXN</li>
                <li>Habitación Sencilla con desayuno: $1,780 MXN</li>
                <li>Habitación Doble con desayuno: $2,000 MXN</li>
                <li>
                  Persona extra en habitación doble solo hospedaje: $300 MXN
                </li>
                <li>
                  Se incluye estacionamiento como parte de las cortesías del
                  hotel.
                </li>
                <li>Botones $60 MXN. por persona (entrada y salida)</li>
                <li>Camaristas $50 MXN. por habitación por noche.</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Elizabeth Gutiérrez</b>
              <br />
              <b>Tel. 477 689 1856 (ext. 514)</b>
              <br />
              <b>elizabeth.gutierrez@metriahotel.com</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>Foro go</b>
            </div>
          </div>

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={200}
                src={require("./../../assets/foro-go/hoteles/hotel-NuevaEstancia.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Hotel La Nueva Estancia
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>
                  Jr. Suite Domo-Alberca – Deluxe sencilla o doble $1,498.00{" "}
                </li>
                <li>Jr. Suite Piso Ejecutivo sencilla o doble $2,498.00</li>
                <li>Master Suite sencilla o doble $2,498.00</li>
                <li>Master Suite Dluxe sencilla o doble $3,598.00</li>
                <li>Suite Presidencial sencilla o doble $4,598.00</li>
                <li>Incluye desayuno americano</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <b>Martín Jiménez Chávez</b>
              <br />
              <b>Tel. +52 (477) 637 2051</b>
              <br />
              <b>mjimenez@grupo330.com.mx</b>
            </div>
            <br />
            <div className="text-center">
              <h3 style={{ color: "#fff" }}>Código de reserva</h3>
              <b style={{ color: "#7CFF01" }}>COFOCE 02 – 24 GO</b>
            </div>
          </div>

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/hs.webp")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              HS Smart
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>
                  HS Smart, Centro Max: $1,596.50 incluye desayuno buffete{" "}
                </li>
                <li>
                  Cargo por 2do desayuno en caso de ocupación doble, $192.50
                  neto.
                </li>
                <li>
                  Desayuno en restaurante “Los Vitrales”, Entre semana en
                  horario de 06:00 a 12:00 hrs. Sábado y domingo 07:00 a 12:00
                  hrs.
                </li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <br />
              <b>Lic. Itzel Uribe</b>
              <br />
              <b>Tel. 477 267-3000 EXT 2013</b>
              <br />
              <b> iuribe@hotssonsmart.com </b>
              <br />
              <br />
              <div className="text-center">
                <h3 style={{ color: "#fff" }}>Código de reserva</h3>
                <b style={{ color: "#7CFF01" }}>FORO</b>
              </div>
            </div>
            <br />
          </div>

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/cuty.png")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              City Express Plus
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <li>Habitación sencilla $ 1,200 netos</li>
                <li>Habitación doble $ 1,320 netos</li>
                <p>
                  Asimismo, podrán disfrutar durante su estancia en Hotel City
                  Express Plus by Marriott León Centro de Convenciones de los
                  siguientes servicios y beneficios:
                </p>
                <li>
                  Desayuno continental en cortesía (lunes a viernes de 6:00am a
                  9:00 am, sáb y dom de 7:00am a 10:00am)
                </li>
                <li>Transporte gratuito 10 km. a la redonda.</li>
                <li>Estacionamiento gratuito.</li>
                <li>Gimnasio gratuito de 6:00 a 22:00 hrs.</li>
                <li>
                  Internet Inalámbrico y alámbrico en habitaciones y áreas
                  comunes.
                </li>
                <li>Centro de Negocios: computadoras, Internet, impresora.</li>
                <li>Cajas de Seguridad dentro de las habitaciones.</li>
                <li>Ubicación privilegiada (Frente a Poliforum).</li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <br />
              <b>Oscar Zavala</b>
              <br />
              <b>Tel. (479) 2621675</b>
              <br />
              <b>cpleo.ventas1@cityexpress.com.mx </b>
              <br />
              <br />
              <div className="text-center">
                <h3 style={{ color: "#fff" }}>Código de reserva</h3>
                <b style={{ color: "#7CFF01" }}>Foro go</b>
              </div>
            </div>
            <br />
          </div>

          <div className=" bg-dark div-card">
            <div className="d-flex justify-content-center">
              <img
                alt=""
                width={100}
                src={require("./../../assets/foro-go/hoteles/hampton-inn.png")}
              />
            </div>
            <hr style={{ borderTop: "2px solid #7CFF01" }} />
            <br />
            <h3 className="text-center" style={{ color: "#ffff" }}>
              Hampton Inn by Hilton Leon
            </h3>
            <div className="d-flex justify-content-center">
              <ul className="">
                <p>Habitación Sencilla – Doble $ 1,400.00 NETO</p>
                <li>La tarifa es para una ó dos personas.</li>
                <li>Persona extra $300.00 más impuestos.</li>
                <li>
                  Las presentes tarifas de convenio están expresadas en pesos
                  mexicanos.
                </li>
                <li>Hora de entrada: 15:00 hrs. Hora de salida: 12:00 hrs.</li>
                <li>Gimnasio gratuito de 6:00 a 22:00 hrs.</li>
                <li>
                  Desayuno americano tipo buffet (L-V 6:00 am a 10:00 am S-D
                  06:00 am a 11:00am)
                </li>
                <li>Internet Wi-Fi</li>
                <li>
                  Acceso al Gimnasio (equipo cardiovascular, de peso integrado y
                  peso libre disponible 24 Hrs.)
                </li>
                <li>Acceso a la piscina. (09:00-21:30)</li>
                <li>Estacionamiento.</li>
                <li>
                  Transportación gratuita 10Km a la redonda (previa reservación,
                  sujeto a disponibilidad)
                </li>
                <li></li>
              </ul>
            </div>
            <br />
            <h3 style={{ color: "#ffff" }} className="text-center">
              Contacto
            </h3>
            <div className="text-center">
              <br />
              <b>Tel. (477)740-3219 </b>
              <br />
              <b>Whatsapp: 477 558 9209</b>
              <br />
              <b>Mail: yadhira.estrada@hilton.com</b>
              <br />
              <b>CC: dai.hernandez@hilton.com</b>
              <br />
              <br />
              <div className="text-center">
                <h3 style={{ color: "#fff" }}>Códigos de reserva</h3>
                <b style={{ color: "#7CFF01" }}>Foro go - FGO</b>
              </div>
            </div>
            <br />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
