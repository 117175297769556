import React, { useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import "./styles.css";
export default function Terms() {
  useEffect(() => {
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Opcional: agrega un desplazamiento suave
      });
    }
    scrollToTop();
  }, []);

  return (
    <>
      <Header />
      <div style={{ background: "#181f2a" }} className="page-title-area">
        <h1>
          TÉRMINOS Y CONDICIONES DEL SERVICIO DE COMPRA DE BOLETOS FORO GO 2024
        </h1>
      </div>
      <section clas className="schedule-area ptb-120 p-5">
        <h3>
          TÉRMINOS Y CONDICIONES DEL SERVICIO DE COMPRA DE BOLETOS FORO GO 2024
        </h3>
        <p>
          El presente documento tiene como finalidad regular la relación que se
          genere por la compra de boletos y/o accesos, entre Usted como USUARIO
          y COFOCE, para el acceso y participación como asistente al Magno
          Evento FORO GO 2024.
        </p>
        <p>
          Cuando se haga referencia en el presente documento a cualquiera de los
          términos descritos en el presente apartado, se entenderá el
          significado aquí atribuido:
        </p>
        <ol>
          <li>
            <strong>TÉRMINOS Y CONDICIONES:</strong> Se refiere al presente
            documento y su observancia es de carácter general para todos los
            usuarios, quienes no podrán acceder a los servicios de compra de
            boletos y/o accesos, sin antes aceptar y reconocer plenamente su
            contenido y alcance legal, asumiendo sus obligaciones y
            consecuencias de derecho.
          </li>
          <li>
            <strong>USUARIO:</strong> Toda aquella persona con plena capacidad
            jurídica, que por voluntad propia, informada y libre, decide acceder
            al servicio de compra de boletos autorizado por COFOCE para su
            acceso al evento.
          </li>
          <li>
            <strong>COFOCE:</strong> La Coordinadora de Fomento al Comercio
            Exterior del Estado de Guanajuato, quien es titular de todos los
            derechos relacionados con el EVENTO y tiene el carácter de
            organizadora del mismo.
          </li>
          <li>
            <strong>evento:</strong> FORO GO 2024, evento de clase mundial, cuya
            finalidad es fomentar la cultura de la exportación mediante la
            innovación para el desarrollo de las empresas exportadoras y con
            potencial exportador del Estado de Guanajuato, a realizarse de
            manera presencial, los días 18 y 19 de Octubre del año 2023.
          </li>
          <li>
            <strong>BOLETO:</strong> Medio impreso y/o digital a través del cual
            el USUARIO comprueba la adquisición de su acceso al EVENTO.
          </li>
          <li>
            <strong>PRECIO:</strong> Pago que realice el USUARIO en favor de
            COFOCE por la obtención de BOLETO (s) y/o acceso (s) al EVENTO,
            mismo que será único y determinado, dependiendo el TIPO DE ACCESO;
            así como las demás especificaciones expuestas en este documento y/o
            en el PORTAL y/o en el Sitio Web{" "}
            <a href="https://foro-go.com">https://foro-go.com</a>.
          </li>
          <li>
            <strong>MÉTODOS DE PAGO:</strong> Tarjeta de débito/crédito (Visa,
            Mastercard y American Express, pago procesado a través de CONECKTA)
            y transferencia electrónica SPEI (pago procesado a través de
            CONECKTA), ya sea en pesos mexicanos o en dólares americanos bajo
            los términos establecidos en el presente documento.
          </li>
          <li>
            <strong>CONFIRMACIÓN DE COMPRA:</strong> Código de barras y/o Código
            QR y/o cualquier otro medio de confirmación de compra autorizado por
            COFOCE, con los datos inherentes a la identificación del BOLETO.
            Sujeto a autorización y aprobación de la entidad financiera emisora
            de la tarjeta con que se efectuó el pago y/o de la pasarela de pago
            a través de la que se realice la compra.
          </li>
          <li>
            <strong>PROGRAMA:</strong> Se refiere al conjunto de ponencias
            magistrales, talleres y/o cualquier otro que tendrá lugar en la sala
            C4 de POLIFORUM León, el cual puede llegar a sufrir modificaciones y
            cambios sin previo aviso.
          </li>
          <li>
            <strong>PROMOCIONES:</strong> Se refiere al conjunto de activaciones
            que COFOCE destine para incentivar la compra de BOLETOS de manera
            masiva al EVENTO.
          </li>
          <li>
            <strong>SEDE:</strong> Se refiere al recinto donde se celebrará el
            EVENTO de manera presencial los días 18 Y 19 de Octubre de 2023 en
            la Sala C4 de POLIFORUM León.
          </li>

          <li>
            <strong>APP:</strong> Se refiere a la aplicación móvil a la que
            tendrán acceso los asistentes presenciales durante los días del
            EVENTO para interactuar entre ellos y los ALIADOS ESTRATÉGICOS.
          </li>

          <li>
            <strong>USUARIOS Y CONTRASEÑAS:</strong> Información que será
            enviada por el Proveedor designado por COFOCE para que el USUARIO
            tenga acceso a la APP, la cual no podrá ser transmitida a ningún
            tercero, ni podrá ser utilizada de forma simultánea en más de un
            equipo.
          </li>

          <li>
            <strong>SERVICIO:</strong> Se refiere al servicio de compra de
            boletos y/o accesos para asistencia al EVENTO; suministrado por
            INNOVACIÓN Y DESARROLLOS SMARTDEV SA DE CV quien detenta todos los
            derechos relacionados, siendo únicamente proveedor del servicio para
            COFOCE.
          </li>

          <li>
            <strong>PORTAL:</strong> Medio único autorizado por COFOCE a través
            del cual se pone a disposición del USUARIO la liga de acceso para
            compra de boletos para asistencia al EVENTO y que será las
            siguiente: Sitio web{" "}
            <a href="https://foro-go.com">https://foro-go.com</a>.
          </li>

          <li>
            <strong>SERVICIO AUTORIZADO:</strong> Se entenderá como el servicio
            acreditado por COFOCE para compra de boletos de acceso al EVENTO,
            mismo que es propiedad de INNOVACIÓN Y DESARROLLOS SMARTDEV SA DE
            CV. y autorizado su uso para COFOCE. Así como envío de facturación a
            los USUARIOS que así lo soliciten.
          </li>

          <li>
            <strong>SMARTDEV:</strong> Nombre comercial de INNOVACIÓN Y
            DESARROLLOS SMARTDEV SA DE CV quien es el propietario del SERVICIO.
          </li>
        </ol>
        <h5>INFORMACIÓN GENERAL</h5>

        <p>
          Al hacer uso del SERVICIO que se ofrece a través del PORTAL, se le
          atribuye a usted el carácter de USUARIO, entendiéndose que con la
          aceptación de los TÉRMINOS Y CONDICIONES está conforme y acepta
          expresa e irrevocablemente los mismos. Por ello, el SERVICIO está
          disponible únicamente para personas mayores de edad (18 años o más).
          Aquellas personas que aún no cuenten con capacidad jurídica para
          obligarse en los términos del presente documento, deberán hacer uso
          del SERVICIO por conducto de sus padres o tutores, quienes adquieren
          absoluta responsabilidad respecto del uso que se haga del PORTAL y/o
          su contenido, hipervínculos, enlaces (links), entre otros.
        </p>

        <p>
          Cualquier persona que no acepte el contenido del presente documento,
          deberá abstenerse de utilizar el PORTAL y/o adquirir el SERVICIO.
        </p>

        <p>
          COFOCE se reserva la facultad de modificar, adicionar, actualizar o
          remover total o parcialmente los TÉRMINOS Y CONDICIONES en cualquier
          momento y sin notificación previa. Cualquier modificación, adición,
          actualización o remoción, será efectiva a partir del momento en que se
          encuentre disponible para el USUARIO en el PORTAL, por lo que será
          responsabilidad exclusiva del USUARIO, revisar los TÉRMINOS Y
          CONDICIONES cada vez que acceda al PORTAL.
        </p>

        <p>
          Igualmente se podrá modificar la presentación, los contenidos, la
          funcionalidad, los productos y/o servicios y la configuración del
          PORTAL, por lo que el USUARIO acepta y reconoce que COFOCE y/o
          SMARTDEV, en cualquier momento podrán interrumpir, desactivar o
          cancelar cualquiera de los elementos que conforman el PORTAL o el
          acceso a éste.
        </p>

        <p>
          El USUARIO se compromete a proporcionar información lícita, completa,
          precisa, veraz y comprobable en los formularios habilitados en el
          PORTAL para la compra de BOLETOS. En todo caso será responsabilidad
          absoluta del USUARIO notificar de forma inmediata a COFOCE acerca de
          cualquier hecho que permita suponer el uso indebido de la información
          registrada, ya sea por robo, extravío o acceso no autorizado a cuentas
          y/o contraseñas, con el fin de proceder a su inmediata cancelación.
          Por ello, el USUARIO deslinda de toda responsabilidad a COFOCE y/o
          cualquier otro proveedor de éste, por cualquier evento desafortunado
          ocasionado al USUARIO por estos supuestos cualesquier otros.
        </p>

        <p>
          En caso de que el USUARIO haya proporcionado datos erróneos o
          incorrectos al momento de la compra, deberá solicitar la corrección de
          éstos al correo electrónico{" "}
          <a href="mailto:soporte.forogo@guanajuato.gob.mx">
            soporte.forogo@guanajuato.gob.mx
          </a>{" "}
          a más tardar dentro de los 5 días naturales previos a que finalice el
          mes en que realizó el pago. En consecuencia, deslinda de cualquier
          responsabilidad a COFOCE, en caso de no solicitar la corrección
          correspondiente de sus datos, dentro del plazo indicado.
        </p>

        <p>
          El tratamiento de los Datos Personales se ajustará al contenido del
          Aviso de Privacidad el cual, se encuentra publicado y puede ser
          consultado en el Sitio Web{" "}
          <a href="https://www.foro-go.com/privacidad">
            https://www.foro-go.com/privacidad
          </a>
          .
        </p>
        <h5>TIPOS DE ACCESO AL EVENTO</h5>

        <h5>BOLETO VIP</h5>
        <ul>
          <li>
            Acceso a las conferencias de manera presencial los días 18 y 19 de
            Octubre 2023, en POLIFORUM León
          </li>
          <li>Kit de bienvenida VIP</li>
          <li>
            Acceso al cocktail de bienvenida el día 17 de Octubre 2023 en lugar
            designado por COFOCE
          </li>
          <li>Asientos numerados y seleccionados por el USUARIO</li>
          <li>Comida en la SEDE el 18 de octubre 2023</li>
          <li>Cocktail de networking el día 19 de octubre 2023</li>
          <li>
            Networking del más alto nivel con otros USUARIOS y ALIADOS
            ESTRATÉGICOS
          </li>
          <li>Encuentro de negocios el día 19 de octubre 2023</li>
        </ul>

        <h5>BOLETO GENERAL</h5>
        <ul>
          <li>
            Acceso a las conferencias de manera presencial los días 18 y 19 de
            Octubre 2023, en POLIFORUM León
          </li>
          <li>Kit de bienvenida</li>
          <li>Cocktail de networking el día 19 de octubre 2023</li>
          <li>
            Networking del más alto nivel con otros USUARIOS y ALIADOS
            ESTRATÉGICOS
          </li>
          <li>Encuentro de negocios el día 19 de octubre 2023</li>
        </ul>

        <h5>
          PRECIOS, PROMOCIONES Y FASES DE VENTA DE BOLETOS O ACCESOS AL EVENTO
        </h5>
        <p>
          Cualquier USUARIO que adquiera su BOLETO, podrá acceder a la PROMOCIÓN
          que corresponda, de acuerdo con la etapa de venta que se tenga
          publicada en el PORTAL. Siempre que lo active de acuerdo con las
          especificaciones aplicables.
        </p>

        <p>
          Las fases de venta, precios y, en su caso, promociones, serán
          determinadas por COFOCE y publicadas por SMARTDEV en el PORTAL.
        </p>

        <p>
          Para cualquier consulta relacionada con los precios, promociones y
          fases de venta de los boletos o accesos al EVENTO, el USUARIO podrá
          consultar la página{" "}
          <a href="https://foro-go.com">https://foro-go.com</a>.
        </p>
        <h5>USO DEL PORTAL</h5>

        <p>
          Para hacer uso del PORTAL, el USUARIO deberá contar con un equipo de
          cómputo o tableta con acceso a internet.
        </p>

        <p>
          Una vez que el USUARIO ingrese al PORTAL, deberá aceptar los presentes
          TÉRMINOS Y CONDICIONES e ingresar información que puede ser
          considerada como datos personales, para su plena identificación; la
          cual será, de forma enunciativa, más no limitativa, la siguiente:
          Nombre(s) del(os) titular(es) ocupante(s) del(os) boleto(s), correo(s)
          electrónico(s), número de boletos a adquirir y datos de facturación,
          para la adquisición de BOLETO(S) y/o accesos al EVENTO.
        </p>

        <p>
          Brindada la información solicitada, el USUARIO dará inicio proceso de
          compra, realizando el pago correspondiente a través de los MÉTODOS De
          pago.
        </p>

        <p>
          Realizado el pago y aceptada la compra del boleto, no habrá cambios,
          cancelaciones, ni devoluciones de ningún tipo, salvo los señalados en
          estos TÉRMINOS Y CONDICIONES.
        </p>

        <p>
          Confirmado que sea el pago, se enviará al USUARIO un correo
          electrónico desde el PORTAL, con la CONFIRMACIÓN DE COMPRA.
        </p>

        <p>
          El envío de USUARIO Y CONTRASEÑA para la activación y acceso a la APP
          el día del EVENTO, se realizará con una semana de anticipación al
          EVENTO, al correo electrónico proporcionado por el USUARIO al momento
          de generar la compra de sus BOLETOS; de manera separada USUARIO Y
          CONTRASEÑA.
        </p>

        <p>
          Se enviará la confirmación de la compra vía correo electrónico con un
          código QR que contiene la información con los datos del USUARIO para
          agilizar el registro, mismo que se abrirá el 17 de Octubre y el día
          del evento en la sala C4 de la SEDE.
        </p>

        <p>
          De manera discrecional, se podrá requerir al USUARIO presentar una
          identificación oficial vigente y/o la comprobación de pago.
        </p>

        <p>
          El USUARIO que adquiera BOLETOS, tendrá únicamente 72 (setenta y dos
          horas) para generar cambio alguno en los datos indicados para la
          generación de su usuario y contraseña, por lo que el USUARIO deberá
          revisar con toda cautela los datos vertidos al momento de su compra.
        </p>

        <p>
          Queda absolutamente prohibida la reventa de los boletos, por lo que el
          USUARIO deslinda a COFOCE, SMARTDEV y/o cualquier otro proveedor del
          EVENTO, sobre cualquier reclamación relacionada, por no corresponder
          los datos de identificación del USUARIO o cualquier otra relacionada
          por la compra de boletos a través de medios no autorizados por COFOCE.
        </p>

        <h5>ESPECIFICACIONES DE PAGO</h5>
        <p>
          Si el USUARIO eligió como MÉTODO DE PAGO Transferencia SPEI a través
          de CONEKTA o la pasarela de pago vigente según sea el caso, el
          comprobante de compra le será enviado hasta 24 horas hábiles después
          de que CONECKTA confirme su pago. En caso de haber seleccionado como
          MÉTODO DE PAGO el uso de Tarjeta de Crédito o Débito, el tiempo de
          CONFIRMACIÓN DE COMPRA será de manera inmediata, una vez autorizado
          por CONECKTA.
        </p>

        <p>
          Si el USUARIO eligió como forma de pago Transferencia SPEI a través de
          CONECKTA, tendrá 5 días naturales a partir de la fecha de solicitud
          para realizar la transferencia/depósito bancario. Si el USUARIO no
          realiza su pago en el periodo que indica éste párrafo, se liberarán
          los boletos, sin responsabilidad alguna para COFOCE y/o SMARTDEV.
        </p>

        <p>
          La compra estará limitada a 30 boletos por persona. Si el USUARIO
          realizó una o más operaciones de compra y excede el límite permitido
          de boletos adquiridos, las solicitudes de compra serán canceladas sin
          previo aviso y sin responsabilidad para COFOCE. En el caso que se
          hubiere hecho algún cargo a su tarjeta de crédito o débito física o
          digital por las compras que se cancelen por los supuestos previstos en
          este párrafo, se reembolsarán la totalidad de los cargos realizados en
          los plazos y tiempos que sean dispuestos por COFOCE y siempre que
          medie reclamación comprobable por parte del USUARIO.
        </p>

        <p>
          El USUARIO es responsable de revisar con su institución bancaria la
          liberación de la tarjeta de crédito y/o débito correspondiente para la
          realización de la compra de los ACCESOS al EVENTO y con ello evitar
          posibles contra cargos.
        </p>

        <p>
          COFOCE no se hace responsable de extravíos, daños, robos, pérdidas de
          comprobantes de compra de los boletos adquiridos, quedando estos bajo
          la exclusiva responsabilidad del USUARIO.
        </p>

        <p>
          En caso de que el EVENTO sea cancelado por causas ajenas a COFOCE o
          por causas de fuerza mayor, el USUARIO podrá solicitar el reembolso
          del importe pagado. Si por cualquier motivo, el EVENTO cambia de
          fecha, el USUARIO contará con 15 días naturales siguientes a que
          COFOCE informe esta situación a través del correo electrónico
          proporcionado por el USUARIO en el PORTAL, para realizar la solicitud
          del reembolso correspondiente en caso de no poder asistir a la nueva
          fecha programada. (Los métodos de reembolso serán publicados por
          COFOCE a través del PORTAL)
        </p>

        <p>
          Para el caso de que el EVENTO sea posfechado y el USUARIO no haya
          solicitado el reembolso de su BOLETO dentro de los plazos concedidos
          para tal efecto, se entenderá que está conforme con la nueva fecha
          propuesta de realización del EVENTO y no habrá lugar a cancelación
          alguna.
        </p>

        <p>
          Solicitado el reembolso, los boletos que haya adquirido el USUARIO
          serán liberados y puestos a la venta, quedando ninguna responsabilidad
          ni compromiso legal alguno de COFOCE para con el USUARIO.
        </p>

        <p>
          Todos los precios que se expresan a través del Portal incluyen el
          impuesto al Valor Agregado y son en Moneda Nacional.
        </p>

        <p>
          No existen cambios ni devoluciones de las compras efectivamente
          realizadas por el USUARIO a través del PORTAL.
        </p>
        <h5>FACTURACIÓN</h5>

        <p>
          En caso de requerir factura, el USUARIO deberá solicitar su expedición
          al momento de la compra, a través del PORTAL, ingresando los datos
          mínimos necesarios para tal efecto. Los cuales serán de forma
          enunciativa, más no limitativa, los siguientes: Razón Social, Registro
          Federal de Contribuyentes, Código Postal, Régimen Fiscal y correo
          electrónico para la recepción de la factura.
        </p>

        <p>
          El comprobante fiscal será enviado al correo electrónico del USUARIO
          en un plazo de 24 a 48 horas hábiles, una vez que CONECKTA confirme su
          pago.
        </p>

        <p>
          En casos específicos, excepcionales y de forma discrecional, COFOCE
          recibirá la solicitud de factura a más tardar dentro de los 5 días
          naturales antes de finalizar el mes en que se efectuó el pago, a
          través del correo electrónico facturas.forogo@guanajuato.gob.mx.
          Debiendo el USUARIO realizar una breve exposición de los motivos por
          los cuales no le fue posible solicitar la factura al momento de la
          compra.
        </p>

        <p>
          La fecha de la factura corresponderá a la fecha de su generación, la
          fecha comprobante de pago podrá indicarse como información adicional.
          COFOCE no tiene responsabilidad alguna si el USUARIO no solicita la
          emisión de la factura dentro del periodo indicado en éste párrafo. En
          caso que el USUARIO no haya solicitado en el tiempo indicado su
          comprobante fiscal, éste se facturará en conjunto con el resto de
          compras sin comprobante fiscal solicitado como Público en General.
        </p>

        <p>
          En caso de solicitudes de reembolso por cancelación o cambio de fechas
          de realización del y sólo en el supuesto de que COFOCE haya emitido
          factura en favor del USUARIO, COFOCE emitirá la nota de crédito que
          corresponda a la factura expedida.
        </p>

        <h5>AVISO DE PRIVACIDAD</h5>
        <p>
          El SERVICIO está sujeto al AVISO DE PRIVACIDAD que se encuentran en la
          página de internet www.foro-go.com/privacidad
        </p>

        <p>
          El uso del SERVICIO y la aceptación de los presentes TÉRMINOS Y
          CONDICIONES, representa el consentimiento expreso e irrevocable del
          USUARIO, respecto del contenido del Aviso de Privacidad. Por
          consiguiente, será responsabilidad exclusiva del USUARIO leer
          cuidadosamente el AVISO DE PRIVACIDAD antes de hacer uso del SERVICIO
          y/o acceder al contenido del PORTAL.
        </p>
        <h5>PROPIEDAD INTELECTUAL</h5>

        <p>
          El USUARIO acepta y reconoce que los productos, gráficos, diseños,
          marcas, interfaces, clips de audio, clips de video, contenido
          editorial y guiones utilizados en el PORTAL, contienen información y
          material confidencial que es propiedad exclusiva de COFOCE y/o sus
          licenciantes, proveedores, aliados estratégicos o cualquier otro y que
          se encuentra protegido por las leyes aplicables en materia de
          propiedad intelectual e industrial.
        </p>

        <p>
          El USUARIO acepta que no utilizará esa información o material
          confidencial de ninguna forma, excepto para utilizar el SERVICIO de
          acuerdo con los presentes TÉRMINOS Y CONDICIONES.
        </p>

        <p>
          La marca FORO GO, el logotipo y otras marcas comerciales, avisos
          comerciales, gráficos, dibujos y/o diseños, reservas de derechos al
          uso exclusivo utilizados en relación con el SERVICIO y/o cualquier
          otro elemento protegido en materia de propiedad intelectual, son
          propiedad exclusiva de COFOCE.
        </p>

        <p>
          Otras marcas comerciales, marcas de servicios, gráficos, software y
          logotipos utilizados en relación con el SERVICIO y/o EL PORTAL y sus
          contenidos, pueden ser marcas comerciales de sus respectivos dueños o
          titulares y así son respetados por COFOCE. El SERVICIO no implica en
          forma alguna, cesión alguna en favor del USUARIO de derecho o licencia
          con respecto a cualquier marca o derecho protegido.
        </p>

        <p>
          La violación a cualquier derecho de propiedad intelectual atribuible
          al USUARIO, será denunciada ante las autoridades competentes por
          COFOCE, SMARTDEV y/o cualquier tercero que considere vulnerados sus
          derechos. Si como consecuencia de la actualización de estos supuestos,
          cualquier autoridad administrativa y/o jurisdiccional llegare a
          requerir información relacionada con el USUARIO presumiblemente
          infractor, ésta será proporcionada por COFOCE sin previo aviso o
          notificación al USUARIO.
        </p>

        <p>
          El uso del PORTAL o el ingreso al sitio web www.foro-go.com podrá
          incluir hipervínculos o enlaces (links) que permitan acceder a páginas
          web de terceros distintos a COFOCE, cuyo contenido o información no es
          revisado, ni avalado por COFOCE, salvo que se establezca expresamente
          lo contrario. Por lo que COFOCE no asume responsabilidad alguna con
          los contenidos y servicios comerciales o de otra índole, aplicaciones
          o páginas web en dichos hipervínculos o enlaces (links).
        </p>
        <h5>RESTRICCIONES</h5>

        <p>
          No se permite la grabación (total o parcial) de los contenidos del
          EVENTO, salvo autorización expresa de COFOCE.
        </p>

        <p>
          De igual manera, ningún contenido del PORTAL, podrá reproducirse para
          fines comerciales o de lucro, de ningún modo o por ningún medio. El
          USUARIO acepta expresa e irrevocablemente no modificar, distribuir o
          crear trabajos derivados basados en el SERVICIO y/o el contenido, ni
          explotará el SERVICIO de ninguna manera no autorizada.
        </p>

        <p>
          El USUARIO acepta que el uso incorrecto del PORTAL y/o el
          incumplimiento de los TÉRMINOS Y CONDICIONES pueden constituir una
          violación de los derechos de autor, así como de propiedad intelectual
          e industrial de los mismos, con independencia de las acciones legales,
          civiles, penales, administrativas y/o de cualquier otro tipo, en que
          pudiera incurrir como consecuencia de su actuar.
        </p>

        <p>
          Además de aquellas establecidas en los presentes TÉRMINOS Y
          CONDICIONES, se establecen las siguientes restricciones:
        </p>

        <ul>
          <li>
            El uso del PORTAL concede al USUARIO única y exclusivamente,
            autorización para uso personal del SERVICIO y el PORTAL; quedando
            expresamente prohibida su utilización para fines comerciales, con
            fines de lucro, directo o indirecto, o inclusive sin fines de lucro,
            incluyendo sin limitación: reproducirlo, comunicarlo, publicarlo,
            trasladarlo, sincronizarlo y/o fijarlo.
          </li>
          <li>
            Utilización de cualquier tipo de software que automatice la
            interacción o descarga de los contenidos o servicios proporcionados
            a través del PORTAL.
          </li>
          <li>
            Suprimir, alterar o manipular cualquier elemento, archivo o
            contenido del PORTAL y por ningún motivo realizará actos tendientes
            a vulnerar la seguridad, los archivos o bases de datos que se
            encuentren protegidos, ya sea a través de un acceso restringido por
            usuario y contraseña, o porque no cuente con los permisos para
            visualizarlos, editarlos o manipularlos.
          </li>
          <li>
            Las restricciones antes mencionadas también incluyen, entre otros,
            textos, gráficos, configuraciones, interfaces, logotipos,
            fotografías y/o cualquier otro contenido en el PORTAL. También se
            prohíbe crear obras derivadas o materiales que provengan o se basen
            en cualquier forma del PORTAL y/o del SERVICIO.
          </li>
          <li>
            Cualquier uso, incluyendo los publicitarios, republicación,
            exhibición, ejecución o comercialización del contenido del PORTAL
            y/o del SERVICIO, las marcas o avisos comerciales, en alguna red de
            cómputo o página de internet, se encuentra estrictamente prohibido,
            salvo consentimiento por escrito de COFOCE.
          </li>
          <li>
            El USUARIO deslinda de toda responsabilidad a COFOCE y/o SMARTDEV
            por daños o perjuicios derivados del acceso, uso o mala utilización
            del contenido del PORTAL y/o del SERVICIO.
          </li>
        </ul>
        <h5>EXCLUSIÓN DE GARANTÍAS / LIMITACIÓN DE RESPONSABILIDAD</h5>

        <p>
          El USUARIO deberá contar con equipo de cómputo o tableta con acceso a
          internet, para la compra de Boletos de Acceso al evento a través de la
          PLATAFORMA que COFOCE designe para tales efectos; sin que sea
          atribuible cualquier clase de responsabilidad hacia COFOCE, SMARTDEV
          y/o el proveedor contratado en caso de falla.
        </p>

        <p>
          COFOCE no garantiza que la PLATAFORMA esté libre de pérdida,
          corrupción, ataque, virus, interferencia, intervención (hacking),
          ingreso u otra alteración de seguridad, por lo que renuncia a
          cualquier responsabilidad relacionada con los mismos.
        </p>

        <p>
          COFOCE no adquiere ninguna responsabilidad por causa de problemas
          técnicos que se pudieran producir en equipos informáticos que se
          utilicen durante la conexión a la red Internet, ya sea a través del
          PORTAL, la APP o de otras páginas Web.
        </p>

        <p>
          Asimismo, el USUARIO acepta y reconoce que la utilización del PORTAL
          y/o el Sitio Web del EVENTO, podrá implicar la utilización de cookies,
          las cuales son utilizadas para analizar la interacción de los USUARIOS
          con el PORTAL, para mejorar el servicio, facilitar la navegación,
          hacerla más amigable para el USUARIO, detectar errores y posibles
          necesidades del mismo, para ofrecer servicios y contenidos de mejor
          calidad, entre otros. En todo caso, la información que se recopile
          será anónima y no se identificará a USUARIOS individuales. En caso de
          que el USUARIO no desee que se recopile este tipo de información, será
          bajo su estricta responsabilidad la inhabilitación, rechazo,
          restricción o eliminación de cookies, directamente desde su navegador.
        </p>

        <p>
          En el supuesto que se rechace el uso de cookies (total o
          parcialmente), el USUARIO podrá continuar haciendo uso del PORTAL y/o
          el Sitio Web referido, aunque podrán quedar deshabilitadas algunas
          funciones.
        </p>
        <h5>XII. REEMBOLSOS</h5>

        <p>
          Una vez que el USUARIO realice la compra de algún producto o servicio
          y reciba la confirmación correspondiente, no se podrán cancelar ni
          modificar los BOLETOS comprados, por lo que no se podrán realizar
          reembolsos o devoluciones, salvo en los casos debidamente expuestos en
          los TÉRMINOS Y CONDICIONES, así como en casos específicos y
          debidamente acreditados. Lo que se realizará en los plazos y formas
          descritos y/o a través de los que indique COFOCE a través del PORTAL.
        </p>

        <h5>XIII. LEGISLACIÓN APLICABLE / JURISDICCIÓN</h5>

        <p>
          Las operaciones que pueden realizarse a través de este SERVICIO se
          regirán por la legislación mercantil. Cualquier controversia que se
          derive de la aplicación de las mismas se ventilará ante los tribunales
          y las autoridades del Municipio de Silao, Guanajuato, renunciando
          expresamente a cualquier otra jurisdicción que les pudiera
          corresponder por razón de sus domicilios presentes o futuros o por
          cualquier otra causa.
        </p>

        <h5>XIV. NOTIFICACIONES Y ACLARACIONES</h5>

        <p>
          Cualquier cuestión, aclaración o queja no establecida en los presentes
          TÉRMINOS Y CONDICIONES, deberá hacerse del conocimiento de COFOCE para
          su debida atención, a través del correo electrónico
          soporte.forogo@guanajuato.gb.mx
        </p>

        <p>
          COFOCE señala como domicilio para recibir notificaciones el ubicado en
          calle Plaza la Paz número 102, Int 11 Puerto Interior, Silao de la
          victoria, Guanajuato, México y correo electrónico
          contactocofoce@guanajuato.gob.mx
        </p>

        <p>Última actualización: 4 de mayo 2023.</p>
      </section>
      <Footer />
    </>
  );
}
