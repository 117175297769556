/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/foro-go/forogo_blanco.png";

const Header = () => {
  const [menuClass, setMenuClass] = useState("dropdown-menu");
  const [isOpen, setIsOpen] = useState(false);

  // const toggleMenu = () => {
  //   setMenuClass(
  //     menuClass === "dropdown-menu" ? "dropdown-menu show" : "dropdown-menu"
  //   );
  // };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  }, []);

  return (
    <header id="header">
      <div id="navbar" className="elkevent-nav">
        <nav className="navbar navbar-expand-md navbar-light">
          <div className="container">
            <Link to="/home" className="navbar-brand">
              <img src={logo} width={200} alt="logo" />
            </Link>
            <button
              type="button"
              aria-expanded={isOpen}
              aria-controls="navbarNav"
              data-bs-toggle="collapse"
              className="navbar-toggler"
              data-bs-target="#navbarNav"
              aria-label="Toggle navigation"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              id="navbarNav"
              className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/speakers" className="nav-link">
                    Speakers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/programa" className="nav-link">
                    PROGRAMA
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    role="button"
                    id="navbarDropdown"
                    onClick={toggleMenu}
                    aria-expanded={isOpen}
                    data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle"
                  >
                    VER MÁS
                  </Link>
                  <ul
                    aria-labelledby="navbarDropdown"
                    className={`dropdown-menu ${isOpen ? "show" : ""}`}
                    onClick={() => {
                      console.log("object");
                    }}
                  >
                    <li className="nav-item">
                      <Link
                        to="/aliados-estrategicos"
                        className="dropdown-item"
                      >
                        Aliados Estratégicos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/hoteles" className="dropdown-item">
                        Hoteles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="https://blog.foro-go.com/"
                        className="dropdown-item"
                      >
                        Blog
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
