import React from "react";
import backgroundImage from "../../assets/foro-go/backgrond/bg_speakers.webp";
import Header from "../Header";

const MailchimpForm = () => {
  const styles = {
    container: {
      clear: "left",
      color: "#fff",
      fontSize: "14px",
      padding: "2rem",
      paddingTop: "5rem",
      fontFamily: "Helvetica, Arial, sans-serif",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${backgroundImage})`,
      width: "100%",
      margin: "0 auto",
      boxSizing: "border-box",
    },
    "@media (max-width: 768px)": {
      container: {
        padding: "30rem",
        fontSize: "12px",
      },
    },
    "@media (max-width: 480px)": {
      container: {
        padding: "2rem",
        fontSize: "10px",
      },
    },
  };

  return (
    <>
      <Header />
      <div style={styles.container} id="mc_embed_shell">
        <div id="mc_embed_signup">
          <form
            action="https://gob.us13.list-manage.com/subscribe/post?u=e09eb1f03d39d8087b921fc2b&amp;id=3ffb5cadaf&amp;f_id=00fa34e2f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <h2 style={{ color: "#ffff" }}>Suscribir</h2>
              <div className="indicates-required">
                <span className="asterisk">*</span> indica que es obligatorio
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Correo Electrónico <span className="asterisk">*</span>
                </label>
                <input
                  required
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  className="form-control required email"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">
                  Nombre <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="FNAME"
                  className="form-control required"
                  id="mce-FNAME"
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE2">
                  Teléfono <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="MMERGE2"
                  className="form-control"
                  id="mce-MMERGE2"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE5">
                  Empresa <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="MMERGE5"
                  className="form-control required"
                  id="mce-MMERGE5"
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE8">
                  Sector al que pertenece tu empresa{" "}
                  <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="MMERGE8"
                  className="form-control required"
                  id="mce-MMERGE8"
                  required
                />
              </div>
              <div className="mc-address-group">
                <div className="mc-field-group">
                  <label htmlFor="mce-ADDRESS-addr1">
                    Dirección <span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="70"
                    name="ADDRESS[addr1]"
                    id="mce-ADDRESS-addr1"
                    className="form-control required"
                    required
                  />
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-ADDRESS-addr2">Address Line 2</label>
                  <input
                    type="text"
                    maxLength="70"
                    name="ADDRESS[addr2]"
                    id="mce-ADDRESS-addr2"
                    className="form-control"
                  />
                </div>
                <div className="mc-address-fields-group d-flex justify-content-between">
                  <div className="mc-field-group">
                    <label htmlFor="mce-ADDRESS-city">City</label>
                    <input
                      type="text"
                      maxLength="100"
                      name="ADDRESS[city]"
                      id="mce-ADDRESS-city"
                      className="form-control required"
                      required
                    />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-ADDRESS-state">
                      State/Province/Region
                    </label>
                    <input
                      type="text"
                      maxLength="100"
                      name="ADDRESS[state]"
                      id="mce-ADDRESS-state"
                      className="form-control required"
                      required
                    />
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-ADDRESS-zip">Postal / Zip Code</label>
                    <input
                      type="text"
                      maxLength="100"
                      name="ADDRESS[zip]"
                      id="mce-ADDRESS-zip"
                      className="form-control required"
                      required
                    />
                  </div>
                </div>
                <div className="mc-field-group">
                  <label htmlFor="mce-ADDRESS-country">Country</label>
                  <select
                    className="form-select form-select"
                    name="ADDRESS[country]"
                    id="mce-ADDRESS-country"
                  >
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cabo Verde">Cabo Verde</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Eswatini">Eswatini</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Greece">Greece</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Ivory Coast">Ivory Coast</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Norway">Norway</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Korea">South Korea</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div id="mce-responses" className="clear foot">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                <input
                  type="text"
                  name="b_e09eb1f03d39d8087b921fc2b_3ffb5cadaf"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input
                    type="submit"
                    value="Suscribir"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="btn btn-primary m-1"
                  />
                  <p className="brandingLogo">
                    <a
                      href="http://eepurl.com/hcSkYf"
                      title="Mailchimp - email marketing made easy and fun"
                    >
                      <img
                        alt=""
                        src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MailchimpForm;
