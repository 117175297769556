import React from 'react'

export const GoTop = ({ delayInMs, scrollStepInPx }) => {

    const scrollToTop = (e) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth' // Para hacer un desplazamiento suave, puedes usar 'auto' para un desplazamiento instantáneo
        });
    }

    const renderTopIcon = () => {
        return (
            <div className='back-to-top' onClick={scrollToTop}>Top</div>
        )
    }

    return (
        <>
            {renderTopIcon()}
        </>
    )
}